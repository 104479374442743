import React, { useEffect, useState } from "react";
import { ModelCanvas } from "./ModelCanvas";

function App() {
  const [alive, setAlive] = useState(false);

  useEffect(() => {
    !alive && console.log("I am.... alive.");
    setAlive(true);
  }, []);

  return (
    <div style={{ overflow: "hidden" }}>
      <p
        style={{
          position: "absolute",
          top: "4px",
          left: "0",
          right: "0",
          textAlign: "center",
          fontFamily: "monospace",
          zIndex: 999,
          color: "white",
        }}>
        <a
          href="https://www.dextools.io/app/en/base/pair-explorer/0xc81c15c635c6f976f146b382b6ebc1e37a638ccb?t=1712370372505"
          target="_blank"
          rel="noreferrer">
          0x62AF5774B54D5186eF3f462fd6eD93BCe2B16e44
        </a>{" "}
        <br />
        @cicada_base
      </p>
      <ModelCanvas />
    </div>
  );
}

export default App;
